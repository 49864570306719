import Bubble from "~/assets/bubble.svg";
import Interface from "~/assets/interface.svg";
import Hero from "~/assets/hero.png";
import Image from "next/image";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { ScrollLink } from "~/components/ScrollLink";
import { useTranslation } from "react-i18next";
import "~/i18n/i18n";
import { useRouter } from "next/router";
import Link from "next/link";

const texts = ["Ahoj", "Hello", "Xin chào"];

export function HeroSection() {
  const [text, setText] = useState(0);

  useEffect(() => {
    const interval = window.setInterval(() => {
      setText((idx) => (idx + 1) % texts.length);
    }, 2500);

    return () => window.clearInterval(interval);
  }, []);

  const { t } = useTranslation();
  const router = useRouter();

  return (
    <section className="mx-auto grid w-10/12 grid-cols-12 items-center gap-2 xl:w-11/12 xl:gap-16">
      <div className="order-2 col-span-12 sm:order-1 sm:col-span-6 xl:col-span-6">
        <motion.h1
          className="xl:title-xxxx-large title-xxxx-large-2 mb-8 text-text-primary"
          initial={{ opacity: 0, scale: 0.7 }}
          animate={{ opacity: 1, scale: 1, transition: { duration: 0.3 } }}
        >
          {t("title")}
        </motion.h1>
        <motion.p
          className="body-large text-text-secondary"
          initial={{ opacity: 0, scale: 0.7 }}
          animate={{
            opacity: 1,
            scale: 1,
            transition: { duration: 0.3, delay: 0.1 },
          }}
        >
          {t("subtitle")}
        </motion.p>
        <motion.div
          className="mt-5 flex w-full flex-wrap items-center gap-8 sm:mt-16 xl:flex-nowrap"
          initial={{ opacity: 0, scale: 0.7 }}
          animate={{
            opacity: 1,
            scale: 1,
            transition: { duration: 0.3, delay: 0.2 },
          }}
        >
          <Link href="/rezervace" className="btn overline-large shrink-0 p-4">
            {t("button")}
          </Link>

          {router.pathname === "/" ? (
            <Link
              href="/jak-to-funguje"
              className="overline-large text-brand-green-primary hover:text-brand-green-secondary"
            >
              {t("text-secondary")}
            </Link>
          ) : (
            <ScrollLink
              href="#jak-to-funguje"
              className="overline-large text-brand-green-primary hover:text-brand-green-secondary"
            >
              {t("text-secondary")}
            </ScrollLink>
          )}
        </motion.div>
      </div>
      <motion.div
        className="relative order-1 col-span-12 mb-12 sm:order-2 sm:col-span-6 sm:mb-0 xl:col-span-6"
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1, transition: { duration: 0.3 } }}
      >
        <div className="relative">
          <Bubble className="absolute -top-12 right-0 w-4/12 xl:-top-0" />
          <AnimatePresence mode="wait">
            <motion.span
              key={text}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.5 } }}
              exit={{ opacity: 0, transition: { duration: 0.5 } }}
              className={`title-x-large absolute right-0 top-4 w-4/12 text-center text-bg-primary md:top-2 lg:top-1 xl:top-10 `}
            >
              {texts[text]}
            </motion.span>
          </AnimatePresence>
        </div>
        <Image
          src={Hero}
          alt="hero"
          className="w-full p-5 pt-5 xl:p-10 xl:pt-10"
        />
        <Interface className="absolute -bottom-[20px] left-1/2 w-7/12 -translate-x-1/2 supports-[backdrop-filter]:backdrop-blur xl:-bottom-[10px] xl:w-full" />
      </motion.div>
    </section>
  );
}
