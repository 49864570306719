import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "~/i18n/i18n";

const comm = [
  "na úřadech",
  "v nemocnicích",
  "s opraváři",
  "kdekoliv nás právě potřebujete",
];
const commVn = [
  "tại các cơ quan chính quyền",
  "tại bệnh viện",
  "với thợ sửa chữa",
  "ở bất cứ nơi nào bạn cần",
];

export function HelpSection() {
  const { t, i18n } = useTranslation();

  const [communication, setComm] = useState(0);

  useEffect(() => {
    const interval = window.setInterval(() => {
      setComm((idx) => (idx + 1) % comm.length);
    }, 2500);

    return () => window.clearInterval(interval);
  }, []);

  return (
    <section className="mx-auto h-[600px] w-10/12 sm:h-[300px] sm:w-10/12 xl:mx-0 xl:w-full">
      <span className="title-xxx-large text-text-primary">
        {t("help-text")}
      </span>
      <AnimatePresence mode="wait">
        <motion.span
          key={communication}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
          className="title-xxx-large block text-brand-green-primary"
        >
          {i18n.language == "cs" && comm[communication]}
          {i18n.language == "vn" && commVn[communication]}
        </motion.span>
      </AnimatePresence>
    </section>
  );
}
