import Phone from "~/assets/phone.png";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import "~/i18n/i18n";

export function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="h-[400px] w-full bg-bg-secondary" id="kontakt">
      <section className="relative mx-auto mt-16 w-10/12 pt-32 text-center sm:text-left xl:w-11/12 xl:max-w-[1200px] ">
        <span className="overline-small mt-32 inline-block text-text-secondary md:mt-16 xl:mt-8">
          {t("contact-us")}
        </span>
        <a
          className="title-xxx-large link block text-brand-green-primary"
          href="mailto:hey@rigo.so"
        >
          hey@rigo.so
        </a>
        <Image
          src={Phone}
          alt="phone"
          className="absolute -top-[400px] right-0 sm:-top-52"
        />
      </section>
    </footer>
  );
}
